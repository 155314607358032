import CatalogHeader from '@common/catalogHeader';
import MediaPlayer from '@common/mediaPlayer';
import MobileHeader from '@common/mobileHeader';
import api from '@services/api';
import showTimePeriod from '@src/helpers/showTimePeriod';
import showTimeReminder from '@src/helpers/showTimeReminder';
import { Table } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';

import { ReactComponent as PlayIcon } from '@ui/icons/play.svg';

import "./styles.less";

const emptyPlayerState = {
  showPlayer : false,
  url: '',
  title: '',
  isAudio: false,
  playing: true,
  hls: false,
}

class Promo20241001Page extends Component {
  state = {
    showTable: true,
    // showTable: false,
    transactions: [],
    group: {},
    loading: false,
    beginDate: undefined,
    endDate: undefined,
    timeZone: undefined,
    deltaDays: undefined,
    deltaHours: undefined,
    deltaMinutes: undefined,
    timer: undefined,
    ...emptyPlayerState,
  }

  componentDidMount() {
    const { showTable } = this.state;

    if (showTable) {
      this.getTransactionList();

      const timer = setInterval(() => this.getTimer(), 1000 * 60);
      this.setState({ timer });
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  getTransactionList = async () => {
    this.setState({loading: true});

    const { status, data } = await api.getPromo(3);

    if (status === 200 && data.status === 0) {
      this.setState({
        transactions: data?.list?.length
          ? data.list.filter((x) => Number(x.ost_spv) > 0)
          : [],
        beginDate: data.date_begin ? new dayjs(data.date_begin, 'YYYY-MM-DD') : undefined,
        endDate: data.date_end ? new dayjs(data.date_end, 'YYYY-MM-DD') : undefined,
        timeZone: data.time_zone,
      }, () => {
        this.getTimer();
      });
    }

    this.setState({loading: false});
  }

  handleClose = () => {
    this.setState({
      ...emptyPlayerState,
      playing: false,
    });
  }

  getTimer = () => {
    const { loading, endDate } = this.state;

    if (endDate) {
      const today = new dayjs();

      if (today <= endDate) {
        const deltaDays = endDate.diff(today, 'days');
        const deltaHours = endDate.add((-1) * deltaDays, 'days').diff(today, 'hours');
        const deltaMinutes = endDate.add((-1) * deltaDays, 'days').add((-1) * deltaHours, 'hours').diff(today, 'minutes');
        this.setState({
          deltaDays,
          deltaHours,
          deltaMinutes,
        })
      } else if (!loading) {
        this.clearTimer();
      }
    }
  }

  clearTimer = () => {
    const { loading, timer } = this.state;

    if (timer && !loading) {
      clearInterval(timer);
      this.setState({
        deltaDays: undefined,
        deltaHours: undefined,
        deltaMinutes: undefined,
        timer: undefined,
      });
    }
  }

  render() {
    const { t, userInfo } = this.props;
    const {
      showTable,
      transactions, loading, showPlayer, url, title, isAudio, hls,
      deltaDays, deltaHours, deltaMinutes, beginDate, endDate, timeZone
    } = this.state;

    const columns = [
      {
        title: '№ п/п',
        dataIndex: 'key',
        className: 'column column_rowNum',
        render: (data, record, idx) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">№ п/п:</div>
            <div className="column-content-block">{idx + 1}</div>
          </div>
        )
      },
      {
        title: 'Сумма личных единиц',
        dataIndex: 'ost_spv',
        key: 'ost_spv',
        className: 'column column_spv',
        render: (sum) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Сумма личных единиц:</div>
            <div className="column-content-block">
              <NumericFormat
                value={Number(sum)}
                displayType={'text'}
                decimalScale={1}
                thousandSeparator=" "
              />
            </div>
          </div>
        ),
      },
      {
        title: 'ID пользователя',
        dataIndex: 'user_id',
        key: 'user_id',
        className: 'column column_userId',
        render: (user_id) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">ID пользователя:</div>
            <div className="column-content-block">{user_id}</div>
          </div>
        ),
      },
      {
        title: 'ФИО',
        dataIndex: 'last_name',
        key: 'last_name',
        className: 'column',
        render: (_, record) => (
          <div className="flex flex-row gap-1 column-row-block">
            <div className="block lg:hidden column-title-block">Фамилия:</div>
            <div className="column-content-block hidden lg:block">
              {record.last_name} {record.first_name}
            </div>
            <div className="column-content-block block lg:hidden">
              {record.last_name} {record.first_name}<br/>
              (ID: {record.user_id})
            </div>
          </div>
        ),
      }
    ];

    const videoUrl = '751eea6fe8ddbf11f502cb5214f8cf1d43fe7cdc334459de6402641f8db34987';
    const videoUrlMobile = '751eea6fe8ddbf11f502cb5214f8cf1d43fe7cdc334459de6402641f8db34987';
    const videoPoster = [
      process.env.REACT_APP_CDN_SELECTEL,
      'files',
      'partners',
      'promo',
      'travel',
      '3',
      '6.jpg'
    ].join('/');

    return (
      <main className="promo-page promo1-page promo2-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName='Твоя поездка в Марокко!' withPaySubscription={false}/>
          {videoUrl && (
            <div className="info__card md-hide">
              <div className="info-item__video">
                <div className="fac__less bas__less">
                  {videoPoster && (<img src={videoPoster} alt="" className="info__video-bg" />)}
                  <div className="info__video--a">
                    <div className="info-video__text">&nbsp;</div>
                    <div
                      className="info__play"
                      onClick={() => this.setState({
                        showPlayer: true,
                        playing: true,
                        title: 'Твоя поездка в Марокко!',
                        isAudio: false,
                        url: videoUrl,
                        hls: true,
                      })}
                    >
                      <PlayIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {videoUrlMobile && (
            <div className="info__card md-show">
              <div className="info-item__video">
                <div className="fac__less bas__less">
                  {videoPoster && (<img src={videoPoster} alt="" className="info__video-bg" />)}
                  <div className="info__video--a">
                    <div className="info-video__text">&nbsp;</div>
                    <div
                      className="info__play"
                      onClick={() => this.setState({
                        showPlayer: true,
                        playing: true,
                        title: 'Твоя поездка в Марокко!',
                        isAudio: false,
                        url: videoUrlMobile,
                        hls: true,
                      })}
                    >
                      <PlayIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="info__card">
            <h2 className="pb-1 text-lg">Условия промоушена:</h2>
            <h3 className="pb-1 text-base">Поездка за счет компании:</h3>
            <ul className="list-disc pl-6">
              <li><strong>Кто может участвовать:</strong> Резиденты The SoulMate Ambassador's Club.</li>
              <li><strong>Время действия:</strong> {showTimePeriod(loading, beginDate, endDate, timeZone)}</li>
              <li><strong>До окончания осталось:</strong> {showTimeReminder(t, loading, endDate, deltaDays, deltaHours, deltaMinutes)}</li>
              <li>
                <strong>Условия получения награды:</strong> сделать 55 личных условных единиц и более.<br />
              </li>
            </ul>
            <br />
            <h3 className="pb-1 text-base">На коммерческой основе:</h3>
            <ul className="list-none pl-6">
              <li><strong>Для Амбассадоров</strong> клуба стоимость тура до 31 января - 2500$, с 1 февраля - 3000$. Предоплата для бронирования места - 400$.</li>
              <li>Данная цена распространяется также на одного члена семьи Амбассадора, который присоединится к путешествию.</li> <br />
            </ul>
            <ul className="list-none pl-6">
              <li>
                <strong>Для не резидентов:</strong> стоимость тура - 3500$. Предоплата - 1000$.
              </li>
            </ul>
          </div>

          <div className="info__card description">
            <h2 className="pb-1 text-lg">Описание промоушена:</h2>
            <p>
              <h3 className="pb-1 text-base">Приглашаем вас в незабываемое путешествие по удивительному Марокко!</h3>

              <p className='w-full 2xl:w-5/12 pl-3'>
                Марокко - настоящая жемчужина Северной Африки, страна,
                которая удивляет воображение своим неповторимым колоритом и завораживающей красотой.
                <br/>
                <br/>
                Здесь переплетаются древние традиции и современность,
                величественные горы и живописные побережья, яркие краски и умиротворяющая атмосфера.
                <br/>
                <br/>
                Эта страна покорит вас своей самобытной культурой,
                невероятной природой и гостеприимством местных жителей.
              </p>

              <h3 className="pb-1 text-base">Маршрут путешествия:</h3>

              <p className="w-full 2xl:w-9/12">
                Наше путешествие начнется с города Агадир, расположенного на берегу Атлантики.
                Здесь вас ждет отдых в комфортабельном отеле и увлекательные занятия серфингом под руководством опытного инструктора.
              </p>
              <p className="w-full 2xl:w-9/12">
                Затем мы отправимся в очаровательный город Эссуэйра,
                где вас ждет пешеходная экскурсия по его живописным улочкам.
              </p>
              <p className="w-full 2xl:w-9/12">
                Завершится наше путешествие в Марракеше - "Красной жемчужине" Марокко.
                Вы посетите знаменитый музей Ива Сен-Лорана, прогуляетесь по роскошному саду
                Маждорель и насладитесь свободным временем в этом удивительном городе.
              </p>
            </p>
            <div><strong>В стоимость тура включено:</strong></div>
            <ul className="list-disc pl-6">
              <li><strong>Авиабилеты:</strong> Москва - Касабланка - Агадир, Марракеш - Касабланка - Москва</li>
              <li><strong>Проживание в комфортабельных отелях 4* (включены завтраки)</strong></li>
              <li><strong>Все трансферы:</strong> аэропорт-отель, отель-аэропорт;</li>
              <li>
                <strong>Экскурсии с русскоговорящим гидом, транспортом и экскурсионным оборудованием:</strong>
                &nbsp;Эссуэйра, Марракеш, Касабланка, серфинг с инструктором в Агадире
              </li>
            </ul>
            <p className='pt-2'>
              Присоединяйтесь к нашему путешествию и откройте для себя удивительный мир Марокко!
            </p>
          </div>
          {/*<div className="static__contents rounded-4">*/}
          <div className="info__card">
            <div className="static__cont">
              <h2 className="pb-1 text-lg">Список участников:</h2>
              {
                showTable
                  ? (
                    <Table
                      sticky={true}
                      pagination={false}
                      tableLayout={'auto'}
                      dataSource={transactions}
                      columns={columns}
                      loading={loading}
                      rowKey={(record) => record.user_id}
                      rowClassName={(record) => classNames(
                        "st-table-line flex-col lg:flex-row !items-start lg:items-center w-full gap-1 lg:gap-0",
                        Number(record.ost_spv || 0) < 10
                          ? 'red'
                          : (
                            Number(record.ost_spv || 0) < 30
                              ? 'yellow'
                              : (
                                Number(record.ost_spv || 0) < 55
                                  ? 'blue'
                                  : 'green'
                              )
                          ),
                        (Number(record.user_id || -1) === Number(userInfo?.id || 0)) && 'current-user'
                      )}
                      className='static-table'
                    />
                  )
                  : <p>Пока нет набора на данный промоушен</p>
              }
            </div>
          </div>
          {videoUrl && (
            <MediaPlayer showPlayer={showPlayer} title={title} url={url} handleClose={this.handleClose} isAudio={isAudio} hls={hls} />
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

export default connect(mapStateToProps)(
  withTranslation()(
    Promo20241001Page
  )
);
