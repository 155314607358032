export const SOFTWARE_VERSION = '2.0.0';

// Ссылки на внешний сайт для экосистемы SoulTeam
export const SOULMATE_ECOSYSTEM = 'https://dao-soulmate-ecosystem.gitbook.io/the-dao-soulmate-ecosystem';

// Ссылки на основной телеграмм-канал компании
export const TELEGRAM_FOR_MAIN = 'https://t.me/+ZHbWIueXuqhhYTAy';

// Ссылки на телеграмм-канал службы поддержки
export const TELEGRAM_FOR_SUPPORT = 'https://t.me/SoulMate_support_bot';

// Ссылки на телеграмм-канал клуба Амбассадоров
export const TELEGRAM_SME_ADMIN_BOT = 'https://t.me/SME_admin_bot';

// Ссылки на телеграмм-каналы факультетов
export const TELEGRAM_FOR_BASE = 'https://t.me/SoulTeamAssistent';
export const TELEGRAM_FOR_PRESALE = 'https://t.me/+mUAy1ZFGEJM5NTMy';
export const TELEGRAM_FOR_DEFI = 'https://t.me/+ntq1dKtsdt84ZjUy';
export const TELEGRAM_FOR_CRYPTO = 'https://t.me/+fNfR8SpiLZBkN2E6';
export const TELEGRAM_FOR_GAMEFI = 'https://t.me/+vS-iB4vuNSs0YzIy';
export const TELEGRAM_FOR_NFT = 'https://t.me/+0tUbsa9EQcljMmMy';

export const NFT_TOKEN_POLYGON_SCAN = 'https://polygonscan.com/nft/';
export const NFT_TOKEN_ELEMENT_MARKET = 'https://element.market/assets/polygon/';

export const APPSTAGE_INITIAL = 'APPSTAGE_INITIAL';
export const APPSTAGE_AUTH = 'APPSTAGE_AUTH';
export const APPSTAGE_SIGNIN = 'APPSTAGE_SIGNIN';
export const APPSTAGE_INITFAIL = 'APPSTAGE_INITFAIL';

export const AUTH_COOKIE_NAME = 'auth_token';
export const DISABLE_DOUBLE_AUTH = 'disable_double_auth';
export const REFRESH_COOKIE_NAME = 'refresh_token';

export const MIN_USER_PASSWORD_LENGTH = 8;

// Максимальная цифра оставшихся дней подписки, которую будем показывать
export const SUBSCRIPTION_MAX_DAYS_VIEW = 30;

export const AVATAR_IMAGE_TYPES = "image/bmp, image/gif, image/jpeg, image/png, image/webp";
export const AVATAR_IMAGE_SIZE = 30 ;
export const AVATAR_IMAGE_SIZE_BYTES = AVATAR_IMAGE_SIZE * 1024 * 1024;

// Locales
export const LANG_RU = 'ru';
export const LANG_EN = 'en';

// Routes
export const ROUTE_ROOT = '/';
// export const ROUTE_CONSULTANT = '/consultant';
export const ROUTE_ACADEMY = '/academy';
export const ROUTE_EDUCATION = '/education';
// export const ROUTE_EDUCATION_BASE           = '/education/base';           // Базовый курс
// export const ROUTE_EDUCATION_KNOWLEDGE      = '/education/knowledge';      // База знаний
// export const ROUTE_EDUCATION_PRESALE        = '/education/presale';        // Presale"
// export const ROUTE_EDUCATION_NFT            = '/education/nft';            // NFT",
// export const ROUTE_EDUCATION_DEFI           = '/education/defi';           // DeFi"
// export const ROUTE_EDUCATION_CRYPTO_TRADING = '/education/crypto_trading'; // Криптотрейдинг"
// export const ROUTE_EDUCATION_GAMEFI         = '/education/gamefi';         // GameFi,

export const ROUTE_PRODUCTS                 = '/products';
export const ROUTE_BULLRAN_INDEX           = '/products/bullran-index/:project_id';
export const ROUTE_PROJECTS                 = '/projects';
export const ROUTE_PROJECTS_BY_ID           = '/projects/:id';

export const ROUTE_AMBASSADORS_CLUB = '/ambassadors_club';
export const ROUTE_AMBASSADORS_CLUB_MY_NFT = '/ambassadors_club/my_nft';
export const ROUTE_AMBASSADORS_CLUB_PROGRAMS = '/ambassadors_club/programs';
export const ROUTE_AMBASSADORS_CLUB_STRUCTURE = '/ambassadors_club/structure';
export const ROUTE_STRUCTURE = '/structure';

export const ROUTE_TELEGRAM_SME_ADMIN_BOT = '/telegram_sme_admin_bot';

export const ROUTE_REPORT = '/report';
export const ROUTE_REPORT_USERS_LIST = '/report/users';
export const ROUTE_REPORT_VOLUME_FIRST_LINE = '/report/volume-first-line';
export const ROUTE_REPORT_VOLUME_USER_LIST = '/report/volume-user-list';
export const ROUTE_REPORT_USERS_SALES = '/report/user-sales'
export const ROUTE_REPORT_LEVEL_RAISE = '/report/level-raise'
export const ROUTE_REPORT_NEXT_FLOW = '/report/next-flow';
export const ROUTE_REPORT_SYSTEM = '/report/system';
export const ROUTE_REPORT_SYSTEM_BUY_TRX = '/report/system/buy-trx';
export const ROUTE_REPORT_SYSTEM_PAY_SERVERS = '/report/system/pay-servers';
export const ROUTE_REPORT_SYSTEM_BUY_PRODUCT_BY_HAND = '/report/system/buy-product-by-hand';

export const ROUTE_REFERRALS_LINKS = '/referrals-link';
export const ROUTE_BONUS_PROGRAMS = '/bonus-programs';
export const ROUTE_STATISTICS = '/statistics';
export const ROUTE_TRANSACTIONS = '/transactions';
export const ROUTE_TRAVELS = '/travels';
export const ROUTE_WITHDRAWAL = '/withdrawal';
export const ROUTE_WALLET = '/wallet';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_LANDING = '/landing';
export const ROUTE_PROMO = '/promo';
export const ROUTE_PROMO_TRAVEL = '/promo/travel';
export const ROUTE_PROMO_TRAVEL_1 = '/promo/travel/1';
export const ROUTE_PROMO_TRAVEL_2 = '/promo/travel/2';
export const ROUTE_PROMO_TRAVEL_3 = '/promo/travel/3';
export const ROUTE_PROMO_JUNIOR = '/promo/junior';
export const ROUTE_PROMO_JUNIOR_1 = '/promo/junior/1';
export const ROUTE_PROMO_JUNIOR_2 = '/promo/junior/2';
export const ROUTE_PROMO_WATCH = '/promo/watch';
export const ROUTE_UNIVERSITY = '/university';
export const ROUTE_UNIVERSITY_MASTER = '/university/master-of-words';
export const ROUTE_UNIVERSITY_LEADER = '/university/leader-for-million';
export const ROUTE_UNIVERSITY_CONSULTANT = '/university/consultant';
export const ROUTE_ANNIVERSARY = '/anniversary';

export const ROUTE_404 = '/404';
export const ROUTE_AUTHORIZATION_IN_PROGRESS = '/authorization_in_process';
export const ROUTE_403 = '/403';

export const ROUTE_TEST = '/test';
export const ROUTE_TEST_PARTNER = '/test-partner';
export const ROUTE_TEST_ID = '/test:id';

export const ROUTE_REGISTRATION_WITH_ID = '/reg/:cur_id';
export const ROUTE_REGISTRATION = '/reg';
export const ROUTE_LOGIN = '/login';
export const ROUTE_LOGIN_TG = '/login-tg';
export const ROUTE_VERIFY_EMAIL = '/verify-email';
export const ROUTE_VERIFY_WITHDRAWAL = '/verify-withdrawal/:verifyToken';
export const ROUTE_VERIFY_RESET_PASSWORD = '/verify-reset-password';
export const ROUTE_VERIFY_UPDATE_USER_INFO = '/verify-update-user-info/:verifyToken';

export const STORAGE_REGISTER_CURATOR_ID = 'regcurid';
export const STORAGE_AUTH_BY_USER = 'authByUserInfo';
export const STORAGE_TG_AUTH_ON_PARTNERS = 'tg_auth_on_partners';

export const LIMIT = 10;
export const PAGE_CHANGER_STEPS = [10, 20, 30, 50, 100];
export const TABLE_SIZE = 'middle';

export const STORAGE_USE_CHUNK = process.env.STORAGE_USE_CHUNK || false;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD__HH-mm';
export const DATE_FORMAT_SHORT = 'DD/MM';

export const ROLE_BEGINNER = 0;
export const ROLE_STUDENT_BASE = 10;
export const ROLE_STUDENT_BASE_PLUS = 110;
export const ROLE_STUDENT_BASE_RUB = 210;
export const ROLE_STUDENT_ACADEMY = 20;
export const ROLE_STUDENT_ACADEMY_PLUS = 120;
export const ROLE_STUDENT_ACADEMY_RUB = 220;
export const ROLE_LIGHT = 30;
export const ROLE_PARTNER = 40;
export const ROLE_BUSINESS = 130;
export const ROLE_AMBASSADOR = 140;
export const ROLE_TEST_DRIVER_1 = 150;
export const ROLE_TEST_DRIVER_2 = 160;
export const ROLE_TEST_DRIVER_3 = 170;
export const ROLE_TEST_DRIVER_4 = 180;
export const ROLE_INVESTOR = 800;
export const ROLE_ADMIN = 999;

export const ROLE_LIST_FOR_BUSINESS = [
  ROLE_LIGHT,
  ROLE_PARTNER,
  ROLE_BUSINESS,
  ROLE_AMBASSADOR,
  ROLE_TEST_DRIVER_1,
  ROLE_TEST_DRIVER_2,
  ROLE_TEST_DRIVER_3,
  ROLE_TEST_DRIVER_4,
];

export const USER_STATUS_INACTIVE = 0;
export const USER_STATUS_ACTIVE = 10;
export const USER_STATUS_WAITING_CHANGE_EMAIL = 20;
export const USER_STATUS_WAITING_CHANGE_EMAIL_VERIFIED = 25;
export const USER_STATUS_BLOCKED = 99;
export const USER_STATUS_DELETED = 999;

export const LEVEL_GRAND_AMBASSADOR = 6;

export const PRODUCT_TYPE_PRODUCT = 'product';
export const PRODUCT_TYPE_UPGRADE = 'product_upgrade';

export const PRODUCT_GROUP_EDUCATION = 1;
export const PRODUCT_GROUP_BUSINESS = 2;

export const FORM_REGISTRATION = 'form_registration';
export const FORM_VERIFY_EMAIL_REPEAT = 'form_verify_email_repeat';
export const FORM_VERIFY_EMAIL = 'form_verify_email';
export const FORM_RESET_PASSWORD = 'form_reset_password';
export const FORM_VERIFY_RESET_PASSWORD = 'form_verify_reset_password';
export const FORM_NEW_PASSWORD = 'form_new_password';
export const FORM_LOGIN = 'form_login';
export const FORM_LOGIN_TG = 'form_login_tg';
export const FORM_FEEDBACK = 'form_feedback';

export const HIDDEN_PRODUCT_PARAMS_AFTER_COUNT = 4;

export const CURRENCY_RUB = '₽';
export const CURRENCY_USDT = 'USDT';
export const CURRENCY_BUI = 'BUI';

export const CURRENCY_RUB_ID = 0;
export const CURRENCY_USDT_ID = 1;
export const CURRENCY_BUI_ID = 2;

export const CURRENCY_NETWORK_TRON = 1;
export const CURRENCY_NETWORK_ETHEREUM = 2;
export const METAMASK_NETWORK_ETHEREUM = '0x1';
export const METAMASK_NETWORK_GOERLI = '0x5';
export const METAMASK_NETWORK_POLIGON = '0x89';

export const CHECK_BALANCE_MINUTES = 20;
export const CHECK_BALANCE_EVERY_SECONDS = 10;

export const COMMISSION_FOR_TRANSACTION = 4;
export const DECIMAL_SCALE = 1;
