export default function parsePhpArray(data) {
  const prepare = (
    data
      .replaceAll(/\n\s*/g, ',')
      .replaceAll(/,\)/g, '}')
      .replaceAll(/Array[\n,]\([\n,]?/g, '{')
      .replaceAll(/ => ([a-zA-Zа\\]*?) Object[\n,]\([\n,]?/g, ' => {[_t] => $1,')
      .replaceAll(/([a-zA-Z\\]+):([a-zA-Z\\]+)/g, "$1#$2")
      .replaceAll(/([a-zA-Z\\]+):([a-zA-Z\\]+)/g, "$1#$2")
      .replaceAll(/"/g, "'")
      .replaceAll(/\\/g, "/")
      .replaceAll(/ => ((0x)?[a-fA-F0-9]{32,64})/g, ' => "$1"')
      .replaceAll(/([a-zA-Zа-яА-Я]+[')]?)([,}])/g, '$1"$2')
      .replaceAll(/ => ([a-zA-Zа-яА-Я]+['(]?)/g, ' => "$1')
      .replaceAll(/\[(.*?)\] => /g, '"$1":')
      .replaceAll(/,"[a-zA-Z_]+?":,/g, ',')
      .replaceAll(/":,/g, '":"",')
      .replaceAll(/":(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}),/g, '":"$1",')
      .replaceAll(/":([,}])/g, '":""$1')
      .replaceAll(/([a-zA-Z0-9]{32,64})([\],)}])/g, '$1"$2')
      .replace(/,$/, '')
      .trim('\n')
  );
  // console.log({
  //   _f: 'ShortTransactions-renderInfo-80',
  //   info: record.info,
  //   prepare,
  // });

  return JSON.parse(prepare);
}